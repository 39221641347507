.imageCard {
    display: flex;
    align-items: center;
    margin-left: 12px;
  }
  .imageCard-img {
    border-radius: 50%;
    object-fit: contain;
    border: 1px solid #714fff;
  }
  
  .imageCard-heading {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #0f0b28;
    margin-left: 8px;
    margin-bottom: 0px;
  }
  .slotBox {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
  .slotBox-larger {
    width: 130px;
    height: 60px;
    border-radius: 10px;
    background-color: #e0dafd;
    padding: 14px 10px;
  }
  
  .slotBox-small {
    border-radius: 8px;
    background: #e0dafd;
    height: 60px;
    padding: 20px 10px;
    margin-left: 12px;
  }
  .slotBox-text {
    color: #0f0b28;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 127.4%;
  }
  
  .doctorHome-main {
    border-radius: 24px 24px 0px 24px;
    border: 1px solid #ebecf0;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 32px 48px;
    width: 100%;
    margin-top: 12px;
  }
  
  .doctorProfile {
    border-radius: 24px;
    background: #f5f4ff;
    padding: 28px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .doctorProfile-info {
    display: flex;
    justify-content: space-between;
    /* margin-top: 16px; */
  }
  
  .doctorProfile-info-name {
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    margin-top: 16px;
    text-align: center;
  }
  
  .doctorProfile-info-img-text {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 20px;
  }
  
  .doctorProfile-info-box {
    display: flex;
    flex-direction: column;
    width: 60%;
    /* margin-top: 12px; */
  }
  
  .doctorProfile-info-box-para {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 8px;
    width: 80%;
  }
  .doctorProfile-info-icon {
    width: 28px;
    height: 28px;
  }
  
  .doctorProfile-lower {
    border-radius: 16px;
    background: #fff;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
  }
  
  .doctorProfile-lower-box {
    display: flex;
  }
  
  .doctorProfile-lower-img-text {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-right: 48px;
    color: #0f0b28;
    min-width: 150px;
  }
  
  .doctorProfile-lower-box-para {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 60px;
  }
  .doctorSlot-box {
    padding: 4px;
    border-radius: 24px 24px 0px 0px;
    background: rgb(207, 200, 255, 0.5);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
      0px 0px 0px 0px rgba(0, 0, 0, 0.1), 0px 0px 1px 0px rgba(0, 0, 0, 0.09),
      0px 0px 1px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(0, 0, 0, 0.01),
      0px 0px 1px 0px rgba(0, 0, 0, 0);
  }
  .doctorSlot-main {
    border-radius: 16px;
    border: 1px solid #ebeaf5;
    padding: 8px;
    display: flex;
    justify-content: space-evenly;
    /* width: 570px; */
    /* height: 120px; */
    margin-bottom: 10px;
  }
  
  .doctorSlot-weekday {
    display: flex;
    border-radius: 16px;
    background: #fff;
    /* width: 150px; */
    /* height: 100px; */

  }
  .doctorSlot-weekday > div {
    /* border-radius: 16px;
    border: none;
    padding: 16px;
    height: 100px; */
    width: 150px;
  }
  
  .doctorSlot-time {
    display: flex;
    border-radius: 16px;
    background: #fff;
    /* width: 214px; */
    margin-left: 16px;
    /* height: 100px; */
  }
  .doctorSlot-time > div {
    /* border-radius: 16px;
    border: none;
    padding: 16px;
    height: 100px; */
    width: 150px;
  }
  
  .doctorSlot-weekday > div:nth-child(3) {
    border-radius: 16px;
    border: none;
    padding: 8px;
    height: 100px;
  }
  
  .doctorSlot-weekday > div:nth-child(2) {
    overflow-y: scroll;
  }
  
  /* .doctorSlot-weekday > div:first-child > div  {
    background: #e6dff9;
    border-radius: 8px;
    height: 32px;
    width: 100px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #0f0b28;
  } */
  
  .doctorSlot-time > div {
    border-radius: 16px;
    border: none;
    padding: 16px;
    height: 100x;
  }
  .doctorSlot-time div:nth-child(2) {
    overflow-y: scroll;
  }
  
  .doctorSlot-cross {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #e5e1fd;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: 16px;
    cursor: pointer;
  }
  
  .doctorSlot-button {
    display: flex;
    justify-content:flex-end;
    /* margin-right: 32px; */
  }
  
  .editDoctorProfile {
    padding: 40px 48px 60px 48px;
    border-radius: 0px 0px 24px 24px;
    border: 1px solid #ebecf0;
    background: #fff;
  }
  
  .editDoctorProfile-upper {
    display: flex;
    justify-content: space-between;
  }
  
  .editDoctorProfile-img {
    width: 103px;
    height: 103px;
    object-fit: contain;
    border: 1px solid #714fff;
    border-radius: 50%;
    padding: 10px;
  }
  
  .editDoctorProfile-lower-main {
    display: flex;
    flex-direction: column;
    margin-top: 48px;
  }
  
  .editDoctorProfile-lower-row {
    display: flex;
    margin-top: -10px;
  }
  
  .editDoctorProfile-lower-select-multi {
    /* border: 1px solid #e3e3e3; */
    width: 485px;
    height: 100px;
  }
  
  .editDoctorProfile-lower-select {
    width: 485px;
    height: 40px;
  }
  
  .editDoctorProfile-lower-select > div {
    border-radius: 12px;
    border: 1px solid #e3e3e3;
    padding: 2px;
  }
  
  .editDoctorProfile-lower-select-multi > div {
    border-radius: 12px;
    padding: 8px 4px;
    height: auto;
    border: 1px solid #e3e3e3;
    height: 100px;
  }
  
  .editDoctorProfile-lower-input {
    border-radius: 12px;
    border: 1px solid #e3e3e3;
    width: 485px;
    height: 40px;
    padding: 16px;
    /* outline: 1px solid #e3e3e3; */
  }
  .editDoctorProfile-lower-row-left {
    display: flex;
    flex-direction: column;
    width: 485px;
  }
  
  .editDoctorProfile-lower-row-main {
    margin-left: 60px;
    display: flex;
    flex-direction: column;
  }
  
  .doctor-desc-tooltip {
    width: 600px;
    height: auto;
    padding: 16px;
    border-radius: 12px;
    background-color: #f1eeff !important;
    color: #1c1b1f !important;
    font-size: 14px !important;
    font-weight: 400 !important;
  }
  
  .doctorTable {
    background-color: #ffffff;
    border-radius: 24px 0px 0px 24px;
    background: #ffff;
  }
  
  .doctorTable > div {
    border: none;
  }
  
  .solid-button-text {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    margin-bottom: 0px;
    color: #3a2ca0;
  }
  
  .slotModal-main {
    border-radius: 16px;
    background: #fafafa;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
      0px 0px 0px 0px rgba(0, 0, 0, 0.1), 0px 0px 1px 0px rgba(0, 0, 0, 0.09),
      0px 0px 1px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(0, 0, 0, 0.01),
      0px 0px 1px 0px rgba(0, 0, 0, 0);
    padding: 20px 20px 12px 20px;
    margin: 32px 0px 16px 0px;
  }
  
  .doctorProfile-border {
    width: 1px;
    background: #e0dafd;
    height: 164px;
  }
  
  .editDoctorProfile-label {
    color: #0f0b28;
    font-size: 16px;
    font-weight: 600;
  }
  
  .modalclose-icon {
    border-radius: 50%;
    background: rgba(15, 11, 40, 0.4);
    width: 32px;
    height: 32px;
    margin-top: 12px;
    margin-left: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .inputText {
    color: #714fff;
    font-size: 14px;
    font-weight: 700;
    margin: 10px 16px 0px 0px;
  }
  