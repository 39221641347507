.edit-modal-label{
    font-size: 14px !important;
    padding-right: 0px !important;
    font-weight: 600 !important;
}

.edit-modal-input{
    font-size: 12px !important;
}

.edit-modal-input::placeholder{
    font-size: 12px !important;
    padding: 0px !important;
    margin: 0px !important;
}

.edit-row-modal{
    margin-bottom: 8px !important;
}