table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}

td ,th{
  padding: 8px;
  padding-left: 6px;
}

  /* headlines with lines */
.decorated{
  overflow: hidden;
  text-align: center;
}
.decorated > span{
 position: relative;
 display: inline-block;
 font-size: 15px;
}
.decorated > span:before, .decorated > span:after{
 content: '';
 position: absolute;
 top: 50%;
 border-bottom: 2px solid;
 width: 100vw;
 margin: 0 20px;
}
.decorated > span:before{
 right: 100%;
}
.decorated > span:after{
 left: 100%;
}

.policyName{
  display: flex;
  justify-content: space-between;
}

.walletButton{
  background-color: none;
  padding: 0px 5px 0px 5px;
  height: 34px;
  font-size: 18px;
  border: 2px solid blue;
}

.policyName p{
  color: blue;
  border-bottom: 1px solid blue;
  cursor: pointer;
}

.username{
  background: none;
  font-size: 13px;
  color: blue;
  border: none;
  /* border-bottom: 1px solid blue; */
  padding: 0px;
  text-align: left;
}

.userDetails{
  font-size: 14px;
}

.startDate{
  font-size: 13px;
}

.cappingValues p{
  font-size: 13px;
  margin-bottom: 2px;
}

.cappingValues{
  margin-top: 10px;
}

.removedOn{
  color:red
}