.modal-header {
  padding: 5px 5px;
  color: white;
}

.modal-body {
  padding: 10px 20px;
}

.modal-footer {
  padding: 5px 5px;
  color: white;
}

button {
  margin: 5px;
  padding: 5px;
}

.icon-style {
  width: 25px !important;
  margin: 4px 2px;
  cursor: pointer;
}

.inline {
  display: inline-block;
  border: 1px;
  margin: 5px;
}

.rtsp-modal-big .modal-content {
  width: 150%;
  left: -26%;
}

.card {
  height: 100%;
}
.patient-card{
  border: none !important;
}
.patient-card label {
  margin: 0;
}

/*.patient-card .card-body {*/
/*  padding: 0 !important;*/
/*}*/

/* @font-face {
  font-family: brandonbold;
  src: url('https://getvisitapp.com/font/BrandonText-Bold.otf')
}

@font-face {
  font-family: brandonmedium;
  src: url('https://getvisitapp.com/font/BrandonText-Medium.otf')
}

@font-face {
  font-family: brandonregular;
  src: url('https://getvisitapp.com/font/BrandonText-Regular.otf')
} */

/* body {
  font-family: brandonregular;
}


.bold {
  font-family: brandonbold
}

.medium {
  font-family: brandonmedium
} */

